import { Box, BoxProps, Flex } from "@chakra-ui/core"
import React from "react"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import { getFixedPrice } from "./utils"

import { useSelector, useDispatch } from "react-redux"
import { BaseRootState, BaseDispatch } from "redux/store"

type SummaryProps = BoxProps & {
  subtotal?: string
  tax?: string
  total?: string
}

export default function Summary({
  subtotal,
  tax,
  total,
  ...props
}: SummaryProps) {

  const { checkout, quiz } = useSelector(
    (state: BaseRootState) => state
  )

  return (
    <Box {...props}>
      <Heading as="span" d="block" size="6" fontWeight="bold" mb="2.2988rem">
        Order Summary
      </Heading>
      <Flex justify="space-between" color="dawn" mb="0.5625rem">
        <Body size="md">Subtotal</Body>
        <Body size="md">{getFixedPrice(subtotal)}</Body>
      </Flex>
      {tax && tax !== "0.0" && (
        <Flex justify="space-between" color="dawn" mb="0.5625rem">
          <Body size="md">Estimated Tax</Body>
          <Body size="md">{getFixedPrice(tax)}</Body>
        </Flex>
      )}
      <Flex justify="space-between" color="dawn" mb="0.5625rem">
        <Body size="md">Shipping</Body>
        <Body size="md">Free</Body>
      </Flex>
      {checkout?.data?.discountCodes?.length != null && checkout?.data?.discountCodes?.length > 0 && (
        <Flex justify="space-between" color="dawn" mb="0.5625rem">
          <Body size="md">Extra {quiz?.discount}% Off</Body>
          <Body size="md"
          // @ts-ignore
          >-{getFixedPrice((parseInt(subtotal)/100) * parseInt(quiz?.discount))}</Body>
        </Flex>
      )}
      <Flex justify="space-between" mb="2.0625rem">
        <Body size="md" fontWeight="semibold">
          Total
        </Body>
        <Body size="md" fontWeight="semibold">
          {getFixedPrice(total)}
        </Body>
      </Flex>
    </Box>
  )
}
