import { useQuery } from "@apollo/react-hooks"
import { Box, Flex, Icon } from "@chakra-ui/core"
import loadable from "@loadable/component"
import { graphql, navigate } from "gatsby"
import React, { ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Badge from "../components/Badge"
import { Button } from "../components/Buttons"
import CartItem from "../components/Cart/CartItem"
import Summary from "../components/Cart/Summary"
import DetailsItem from "../components/DetailsItem"
import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import ModalButton, { ModalButtonProps } from "../components/ModalButton"
import Body from "../components/typography/Body"
import Heading from "../components/typography/Heading"
import SmallCaps from "../components/typography/SmallCaps"

import { CartPageQuery } from "../../graphql-types"
import CartPageAddons from "../components/Cart/CartPageAddons"
import {
  getFixedPrice,
  getPriceValue,
  groupCustomizedLineItems,
} from "../components/Cart/utils"
import {stringToBase64} from "../utils/text";
import useGoogleAnalytics from "../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import useLoginDrawer from "../context/loginDrawer"
import {
  CartItem as CartItemProps,
  getNormalizedCustomAttributes,
} from "../redux/models/checkout"
import { BaseDispatch, BaseRootState } from "../redux/store"
import {
  GetShopifyVariantPricing,
  GetShopifyVariantPricingResponse,
} from "../shopify/graphql/queries"
import { isValidUserLoggedIn } from "../utils/auth"
import { bp } from "../utils/MediaQueries"
import { rawRichTextToString } from "../utils/richText"
import { formatCheckoutURL } from "../utils/url"

const LoadableAffirmWidget = loadable(
  () => import("../components/AffirmWidget")
)

type CartPageProps = {
  data: CartPageQuery
}
export type Accessory = {
  contentful_id: string
  shopifyId: string
  internalTitle: string
  productListImage: any
  title: string
  topLevelImages: any
  type: string
  variants: any
  pricing: any
}

const UnavailableDetailsItem = () => (
  <DetailsItem display="flex" alignItems="center" mb="0.75rem">
    <Icon name="warning" color="daylight" mr="0.375rem" />
    <SmallCaps as="span" fontWeight="semibold">
      Not available for sale
    </SmallCaps>
  </DetailsItem>
)

const PrimaryModalButton = (props: ModalButtonProps) => (
  <ModalButton
    h={bp("3.75rem", "4.25rem")}
    flex={bp(1, "none")}
    mr="1.25rem"
    mb="1.25rem"
    color="primary"
    borderColor="primaryDividerLine"
    _hover={{
      backgroundColor: "primaryDividerLine",
    }}
    {...props}
  />
)

export default function CartPage({
  data: { messages, bicycles },
}: CartPageProps) {
  const {
    lineItems,
    quantity,
    subtotal,
    tax,
    total,
    webUrl,
    bikes,
    accessories,
    isCartLoading,
    warranty,
  } = useSelector((state: BaseRootState) => {
    return ({
      lineItems: state.checkout.data?.lineItems?.edges || state.checkout.data?.lines?.edges,
      quantity: state.checkout.quantity,
      subtotal: state.checkout.data?.lineItemsSubtotalPrice?.amount || state.checkout.data?.cost?.subtotalAmount?.amount,
      tax: state.checkout.data?.totalTaxV2?.amount || state.checkout.data?.cost?.totalTaxAmount?.amount,
      total: state.checkout.data?.totalPriceV2?.amount || state.checkout.data?.cost?.totalAmount?.amount,
      webUrl: state.checkout.data?.webUrl || state.checkout.data?.checkoutUrl,
      bikes: state.bikes.bikes,
      accessories: state.bikes.accessories,
      isCartLoading: state.checkout.isLoading,
      warranty: state.bikes.warranty,
    })
  })
  const dispatch = useDispatch<BaseDispatch>()
  const { data: pricingData } = useQuery<GetShopifyVariantPricingResponse>(
    GetShopifyVariantPricing,
    {
      variables: {
        productIds: lineItems
          ?.map((item) => item?.node?.merchandise?.id)
          ?.filter((id) => !!id),
      },
    }
  )
  if(pricingData?.nodes !== null) {
    pricingData?.nodes.map((item) => {
      item.id = stringToBase64(item.id);
      item.product.id = stringToBase64(item.product.id);
      return item;
    })
  }
  const { openLoginDrawer } = useLoginDrawer()
  const isLoggedIn = isValidUserLoggedIn({ 
    accessToken: useSelector((state: BaseRootState) => state.user.accessToken), 
    tokenExpiration: useSelector((state: BaseRootState) => state.user.tokenExpiration) 
  })

  useEffect(() => {
    dispatch.sidebar.setIsOpen(false)
  }, [])

  dispatch.checkout.createDiscount('sdvsvsvsdv')

  const groupedLineItems = groupCustomizedLineItems(lineItems || [])

  const [selectedAccessories, setSelectedAccessories] = React.useState<
    Accessory[]
  >([])

  const onAccessoryChange = (accessory: Accessory) => {
    const isAccessoryPresent = selectedAccessories?.find(
      (acc) => acc?.contentful_id === accessory?.contentful_id
    )
    if (isAccessoryPresent) {
      setSelectedAccessories(
        selectedAccessories?.filter(
          (acc) => acc?.contentful_id !== accessory?.contentful_id
        )
      )
    } else {
      setSelectedAccessories([...selectedAccessories, accessory])
    }
  }

  const [hasWarranty, setHasWarranty] = React.useState(false)
  const [addedToCart, setAddedToCart] = React.useState(false)

  // Google Analytics Added Warranty
  const warrantyGA = useGoogleAnalytics({
    category: "Warranty",
    action: "Added Product",
    shouldFireOnFirstRender: false,
  })
  const addBicycleToCartGA = useGoogleAnalytics({
    category: "Bicycle",
    action: "Added Product",
    shouldFireOnFirstRender: false,
  })

  const allLineItemIds = lineItems?.map(
    (lineItem) => lineItem?.node?.variant?.id
  )

  const onAddToCart = () => {
    let itemList: { [cartItem: string]: {} }[] = []

    if (Array.isArray(selectedAccessories) && selectedAccessories.length > 0) {
      selectedAccessories
        .filter(
          (addon) =>
            !allLineItemIds?.includes(addon?.shopifyId) &&
            Object.keys(addon).length
        )
        .map((upsellItem) => {
          itemList.push({
            cartItem: {
              type: "accessory",
              productSlug: upsellItem?.internalTitle,
              contentfulProductId: upsellItem?.contentful_id,
              contentfulVariantId: upsellItem?.contentful_id,
              variantId: upsellItem?.shopifyId,
              isPreorder: false,
              preorderInfo: "",
            } as CartItemProps,
            // childCartItems: [],
          })
        })
    }

    dispatch.checkout.addToCartBulk(itemList)
    // dispatch.checkout.addToCart(item)
    setAddedToCart(true)
    addBicycleToCartGA.fireEvent()
  }

  // ------Cart Addons------

  // Filter only Product Variants which have cartAddons
  const allBicyclesWithCartAddons: any = bicycles?.edges?.filter((edge: any) =>
    edge?.node?.speeds?.[0]?.variants?.find((variant: any) =>
      allLineItemIds?.includes(variant?.shopifyId)
    )
  )

  // @ts-ignore
  let allCartAddons = allBicyclesWithCartAddons
    ?.flatMap((cartItem) => cartItem?.node?.speeds || [])
    .flatMap((speed) => speed.variants || [])
    .filter((variant) => allLineItemIds?.includes(variant?.shopifyId))
    .flatMap((variant) =>
      variant?.cartAddons
        ? variant.cartAddons.filter(
            (addon) =>
              !allLineItemIds?.includes(addon?.shopifyId) &&
              Object.keys(addon).length
          )
        : []
    )
    .filter((addon, index, self) => {
      return self.findIndex((a) => a?.shopifyId === addon?.shopifyId) === index
    })

  return (
    <Box>
      {!lineItems?.length ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          my="13.4531rem"
        >
          <Heading size="4" fontWeight="bold" mb="1.4375rem">
            Your cart is empty
          </Heading>
          {!isLoggedIn && (
            <>
              <Body size="md" color="dawn" mb="1.7831rem">
                Have an account? Sign in to see your cart
              </Body>
              <Button onClick={() => openLoginDrawer()}>Sign in</Button>
            </>
          )}
        </Flex>
      ) : (
        <>
          {/* <NoticeBanner heading="Delayed Shipping Times">
            Thank you for supporting our business during these unprecedented
            times. Due to an extreme increase in order volume, shipping times
            will be longer than usual.
          </NoticeBanner> */}
          <MaxWidthGrid pt={bp("2.0244rem", "6.8125rem")}>
            {/* Cart Items */}
            <Flex
              gridColumn={["1/4", null, null, null, "2/14", "2/10"]}
              gridRow={bp(1)}
              flexDirection="column"
            >
              <Flex
                w={bp("100%")}
                justify={bp("center", "flex-start")}
                mb="2.375rem"
              >
                <Heading size="3" fontWeight="bold" mr="0.25rem">
                  Cart
                </Heading>
                <Badge
                  number={groupedLineItems.length}
                  transform={["translateY(-0.3125rem)", ""]}
                />
              </Flex>
              <Box>
                {groupedLineItems?.map((item) => {
                  const attributes = getNormalizedCustomAttributes(
                    item.attributes
                  )
                  const type = attributes["type"]
                  const slug = attributes["productSlug"]
                  const contentfulProductId = attributes["contentfulProductId"]
                  const contentfulVariantId = attributes["contentfulVariantId"]
                  const isPreorder = attributes["isPreorder"]
                  const preorderInfo = attributes["preorderInfo"]
                  const itemPrice = getPriceValue(
                    pricingData?.nodes || [],
                    stringToBase64(item.merchandise?.id)
                  )
                  const productPrice =
                    itemPrice +
                    item.accessories.reduce((acc, accessory) => {
                      return (
                        acc +
                        getPriceValue(
                          pricingData?.nodes || [],
                          stringToBase64(accessory.merchandise?.id)
                        )
                      )
                    }, 0)
                  const warrantyPrice = item.warranty
                    ? getPriceValue(
                        pricingData?.nodes || [],
                        stringToBase64(item.warranty.merchandise?.id)
                      )
                    : 0
                  const price = getFixedPrice(
                    (productPrice + warrantyPrice).toString()
                  )

                  let formattedTitle = ""
                  let details: ReactNode
                  let testRideMessage: string | undefined
                  let onEditCustomizations: VoidFunction = () => {}
                  switch (type) {
                    case "bike":
                      const bicycle = bikes.find(
                        (bike) => bike.internalTitle === slug
                      )
                      const product = bicycle?.speeds?.find(
                        (speed) => speed?.contentful_id === contentfulProductId
                      )
                      const variant = product?.variants?.find(
                        (variant) =>
                          variant?.contentful_id === contentfulVariantId
                      )
                      formattedTitle = bicycle?.formattedTitle!
                      details = (
                        <>
                          {!itemPrice && (
                            <UnavailableDetailsItem />
                          )}
                          <DetailsItem>{variant?.color?.name}</DetailsItem>
                          <DetailsItem>
                            {item.warranty
                              ? "Forever Warranty"
                              : "1 Year Warranty"}
                          </DetailsItem>
                        </>
                      )
                      testRideMessage = bicycle?.enable365DayTestRideMessage
                        ? "30 Day Test Ride"
                        : bicycle?.enable90DayTestRideMessage
                        ? "30 Day Test Ride"
                        : undefined
                      onEditCustomizations = () => {
                        navigate(
                          `/customizer/${bicycle?.internalTitle}?edit=${stringToBase64(item.id)}`
                        )
                      }
                      break
                    case "accessory":
                      const accessory = accessories.find(
                        ({ node }) => node.internalTitle === slug
                      )?.node
                      const accVariant = accessory?.variants?.find(
                        (variant) =>
                          variant?.contentful_id === contentfulVariantId
                      )

                      formattedTitle = accessory?.title || item.title || ""
                      // @ts-ignore
                      let variantTitle = item?.merchandise?.title

                      details = (
                        <>
                          <DetailsItem>
                            {accVariant?.color?.name
                              ? accVariant?.color?.name
                              : variantTitle}
                          </DetailsItem>
                        </>
                      )
                      break
                    case "warranty":
                      formattedTitle = "Forever Warranty"
                      break
                  }

                  return (
                    <CartItem
                      key={item.id}
                      w="100%"
                      mb="1.25rem"
                      formattedTitle={formattedTitle}
                      details={details}
                      customizations={item.accessories.map((acc) => ({
                        name: acc.merchandise.title || "",
                        image: acc.merchandise?.image?.url || "",
                      })) as { name: string; image: string }[]}
                      onEditCustomizations={onEditCustomizations}
                      image={item.merchandise?.image?.url || ""}
                      imageMaxHeight={type !== "bike" ? "12.5rem" : undefined}
                      price={price}
                      quantity={item.quantity}
                      quantityAvailable={itemPrice}
                      isLoading={isCartLoading}
                      preorderLabel={isPreorder ? preorderInfo : undefined}
                      testRideMessage={testRideMessage}
                      onUpdateQuantity={(quantity) => {
                        const updatedItems = {
                          [stringToBase64(item.id)]: {
                            ...attributes,
                            variantId: item.merchandise?.id,
                            quantity,
                          },
                        }

                        // Update child cart items as well
                        if (item.accessories.length > 0) {
                          item.accessories.forEach((acc) => {
                            updatedItems[acc.id] = {
                              ...getNormalizedCustomAttributes(
                                acc.customAttributes
                              ),
                              variantId: acc.merchandise?.id,
                              quantity,
                            }
                          })
                        }
                        if (item.warranty) {
                          updatedItems[stringToBase64(item.warranty.id)] = {
                            ...getNormalizedCustomAttributes(
                              item.warranty.attributes
                            ),
                            variantId: item.warranty.merchandise?.id,
                            quantity,
                          }
                        }

                        dispatch.checkout.updateCheckoutItems(updatedItems)
                      }}
                    />
                  )
                })}
                <Flex mb="3.9337rem">
                  <PrimaryModalButton
                    iconName="shipping"
                    text="Free Shipping"
                    title={messages?.freeShippingModalTitle || ""}
                    modalBody={rawRichTextToString(
                      messages?.freeShippingMessage?.raw
                    )}
                  />
                </Flex>
              </Box>
            </Flex>
            {/* Summary */}
            <Box
              gridColumn={["1/4", null, null, null, "2/14", "11/end"]}
              gridRow={[2, null, null, null, null, "auto"]}
              mb={bp("2.5463rem")}
              mr={[null, null, null, null, null, "4rem"]}
            >
              <Summary subtotal={subtotal} tax={tax} total={total} />
              <Box
                as="a"
                // @ts-ignore
                href={formatCheckoutURL(webUrl)}
                w="100%"
              >
                <Button w="100%">Proceed to Checkout</Button>
              </Box>
              <Box textAlign="center" mt="1.375rem" pb={"1px"}>
                <LoadableAffirmWidget
                  pageType="cart"
                  amount={Number(total?.replace("$", "")) || 0}
                />
              </Box>
              <Flex
                borderTop="1px solid"
                borderColor="dividerLine"
                flexDirection="column"
                mt={"20px"}
                w="100%"
              >
                {allCartAddons.length > 0 && (
                  <CartPageAddons
                    onAddToCart={onAddToCart}
                    cartAddons={allCartAddons}
                    selectedAccessories={selectedAccessories}
                    onAccessoryChange={onAccessoryChange}
                    isCartLoading={isCartLoading}
                  />
                )}
              </Flex>
            </Box>
          </MaxWidthGrid>
        </>
      )}
    </Box>
  )
}

export const query = graphql`
  query CartPage {
    messages: contentfulGlobalConfiguration {
      freeShippingMessage {
        raw
      }
      dayTestRideDescription {
        raw
      }
      freeShippingModalTitle
      testRideModalTitle
    }
    bicycles: allContentfulBicycleModel(filter: { hidden: { ne: true } }) {
      edges {
        node {
          title
          speeds {
            internalTitle
            variants {
              shopifyId
              accessoryUpsell {
                contentful_id
                title
                accessory_model {
                  title
                }
                shopifyId
                internalTitle
                imageLayout {
                  internal {
                    content
                  }
                }
                productListingImage {
                  fluid(maxWidth: 1000, quality: 85) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                color {
                  hexCode
                  name
                }
              }
              cartAddons {
                contentful_id
                title
                accessory_model {
                  title
                }
                shopifyId
                internalTitle
                imageLayout {
                  internal {
                    content
                  }
                }
                productListingImage {
                  fluid(maxWidth: 1000, quality: 85) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                color {
                  hexCode
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
